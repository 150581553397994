<template>
  <div  class="content ma-5 mb-16 px-0 px-sm-16" >

brand detail

  </div>
</template>

<script>

import userApi from '@/api/user'
import { mapState } from 'vuex'



export default {


  data: () => ({
    itemid : 0,
    iteminfo : null,
    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.domain,
    }),
  },
  created() {

    let itemid = this.$route.query.id
    itemid = parseInt(itemid)

    this.itemid = itemid
    let params = {id:itemid}
    userApi.branddetail(params).then((data) => {
      this.iteminfo = data.data.goods_detail
      
      let imglist = this.iteminfo.goods_img_list
      imglist.forEach(img => {
        this.images.push(img.pic_cover_big)
        this.thumbs.push(img.pic_cover_micro)
      });

      let speclist = this.iteminfo.spec_list
      if (speclist.length>0) {
        this.skulist1 = speclist[0]
      }
      if (speclist.length>1) {
        this.skulist2 = speclist[1]
      }
      

      

    })

    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.goods-item {
  cursor: pointer;
}



</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .categorybanner .v-image__image--cover
      background-position : center

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .categorybanner .v-image__image--cover
      background-position : 17% !important
  
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000000

</style>